import React, { Component } from 'react';
import './SponsorCarousel.css'
import Api from '../../scripts/Api';
import CarouselItem from './CarouselItem';

class SponsorCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0
        };
        this.node = React.createRef();
        this.sponsorIndex = 0;
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/sponsor", b => {
            this.sponsors = b
            this.initNodes();
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
        var _this = this;
        this.intervalID = setInterval(() => {
            _this.setState({offset: _this.state.offset+1});
        }, 5000);
        this.timeoutID = setTimeout(() => {
            _this.setState({offset: _this.state.offset+1});
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        clearTimeout(this.timeoutID);
    }

    initNodes() {
        // init nodes
        var minNodes = Math.ceil(this.node.current.offsetWidth/220) + 2;
        var noNodes = this.sponsors.length;
        while (noNodes < minNodes && noNodes > 0) {
            noNodes *= 2;
        }
        this.noNodes = noNodes;
    }

    render() {        
        if (this.node.current && this.sponsors) {
            this.sponsorNodes = [];
            var x = -220;
            for (var i = 0; i < this.noNodes; i++) {
                var pos = x - ((this.state.offset % this.noNodes) * 220);                
                if (pos < -220) {
                    pos = this.noNodes * 220 + pos;
                }
                this.sponsorNodes.push(<CarouselItem sponsor={this.sponsors[this.sponsorIndex]} key={x} left={pos}></CarouselItem>);
                this.sponsorIndex = (this.sponsorIndex+1)%this.sponsors.length;
                x = x + 220;
            }
        }
        return <div className="sponsor-carousel">
            <div className="sponsor-carousel-text">Herzlichen Dank unseren Sponsoren!</div>
            <div className="sponsor-carousel-content" ref={this.node}>
                <div className="sponsor-carousel-shadow-left"></div>
                {this.sponsorNodes}
                <div className="sponsor-carousel-shadow-right"></div>
            </div>
        </div>;
    }

}

export default SponsorCarousel;