class Api {

    constructor() {
        this.cache = {};
    }

    setBaseUrl(url) {
        this.baseUrl = url;
    }

    get(path, onSuccess, onError, maxAge, queryObj) {
        var q = "";
        for (var key in queryObj) {
            q += queryObj[key];
        }
        var cacheEntry = this.cache["get"+path+q];
        if (cacheEntry && new Date() - cacheEntry.timestamp < maxAge*1000) {
            onSuccess(cacheEntry.data);
            return;
        }
        var _this = this;
        this._send(path, null, r => {
            _this.cache["get"+path+q] = {timestamp: new Date(), data: r};
            onSuccess(r);
        }, onError, "GET", "application/json", queryObj);
    }

    post(path, object, onSuccess, onError) {
        this._send(path, JSON.stringify(object), onSuccess, onError, "POST", "application/json");
    }

    postForm(path, object, onSuccess, onError) {
        this._send(path, object, onSuccess, onError, "POST");
    }

    postURLEncoded(path, object, onSuccess, onError) {
        this._send(path, object, onSuccess, onError, "POST", "application/x-www-form-urlencoded");
    }

    put(path, object, onSuccess, onError) {
        this._send(path, JSON.stringify(object), onSuccess, onError, "PUT", "application/json");
    }

    delete(path, object, onSuccess, onError) {
        this._send(path, JSON.stringify(object), onSuccess, onError, "DELETE", "application/json");
    }

    putForm(path, object, onSuccess, onError) {
        this._send(path, object, onSuccess, onError, "PUT");
    }

    _send(path, object, onSuccess, onError, method, contentType, queryObj) {
        if (!this.baseUrl) {
            console.log("Api base url not set!");
            return;
        }
        var _this = this;
        var url = this.baseUrl+path;
        if (queryObj) {
            var query = "";
            var first = true;
            for (var key in queryObj) {
                if (first) query += "?"+key+"="+queryObj[key];
                else query += "&"+key+"="+queryObj[key];
            }
            url += query;
        }
        var options = {
            method: method,
            body: object
        };
        if (contentType) {
            options.headers = {
                "Content-Type": contentType
            }
        }
        fetch(url, options).then(response => {
            if (_this._isJSON(response.headers.get("content-type"))) {
                response.json().then(body => {
                    if (response.ok) {
                        if (onSuccess) onSuccess(body);
                    }
                    else {
                        if (onError) onError(body);
                    }
                });
            }
            else {
                response.blob().then(body => {
                    if (response.ok) {
                        if (onSuccess) onSuccess(body);
                    }
                    else {
                        if (onError) onError({error: "internal_server_error"});
                    }
                });                
            }
        }).catch(e => {
            console.log(e);
            if (onError) onError({error: "no_connection"});
        });
    }

    _isJSON(headerString) {
        if (!headerString) {
            return false;
        }
        var splitted = headerString.split("; ");
        for (var i = 0; i < splitted.length; i++) {
            if (splitted[i] === "application/json") {
                return true
            }
        }
        return false;
    }
}

export default new Api();