import React, { Component } from 'react';
import './Board.css'
import ContentCard from '../../../components/content-card/ContentCard';
import Parallax from '../../../components/parallax/Parallax';
import BoardImage from '../../../images/board.jpg';
import Api from '../../../scripts/Api';
import BoardItem from './BoardItem';

class Board extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/board", b => {
            this.members = b.map(m => <ContentCard key={m.id}><BoardItem member={m}></BoardItem></ContentCard>);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <div>
                <Parallax className="board-parallax">
                    <img className="board-background" src={BoardImage} alt=""></img>
                </Parallax>
                {this.members}
            </div>
        );
    }

}

export default Board;