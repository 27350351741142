import React, { Component } from 'react';
import './BoardItem.css'
import Api from '../../../scripts/Api';
import PlaceholderImage from '../../../images/board-placeholder.png'

class BoardItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/board/"+this.props.member.id+"/image", b => {
            if (b.size > 0) {
                this.image = URL.createObjectURL(b);
            }
            else {
                this.image = PlaceholderImage;
            }
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <div className="board-item">
                <div className="board-item-flex">
                    <img className="board-item-image" src={this.image} alt=""/>
                    <div className="board-item-info">
                        <h3 className="board-item-job">{this.props.member.job}</h3>
                        <div className="board-item-name">{this.props.member.name}</div>
                        <div className="board-item-since">{"Im Vorstand seit der Saison " + this.props.member.since}</div>
                    </div>
                </div>
            </div>
        );
    }

}

export default BoardItem;