import React, { Component } from 'react';
import './Teams.css'
import ContentCard from '../../../components/content-card/ContentCard';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import Parallax from '../../../components/parallax/Parallax';

import TeamsImage from '../../../images/teams.jpg';
import Api from '../../../scripts/Api';
import TeamItem from './team-item/TeamItem';
import Router from '../../../scripts/Router';
import TeamDetail from './team-detail/TeamDetail';

class Teams extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.listenerID = Router.addChangeListener(this.updateRoute.bind(this));
        this.setState({loading: true});
        Api.get("/teams", b => {
            this.teamObjects = b;
            this.teams = b.map(t => <TeamItem key={t.id} team={t}></TeamItem>);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    componentWillUnmount() {
        Router.removeChangeListener(this.listenerID);
    }

    updateRoute() {
        this.setState({currentTeam: Router.getPath(1)})
    }

    render() {
        if (this.state.currentTeam && this.teamObjects) {
            var teamName = decodeURI(this.state.currentTeam);
            return <TeamDetail team={this.teamObjects.filter(t => t.name===teamName)[0]}></TeamDetail>
        }
        return (
            <div className="teams">
                <Parallax className="teams-parallax">
                    <img className="teams-background" src={TeamsImage} alt=""></img>
                </Parallax>
                <div className="teams-content">
                    {this.state.loading && <ContentCard><div className="teams-content-loader"><ActivityIndicator></ActivityIndicator></div></ContentCard>}
                    {this.teams}
                </div>
            </div>
        );
    }

}

export default Teams;