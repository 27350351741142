import React, { Component } from 'react';
import './TeamItem.css'
import ContentCard from '../../../../components/content-card/ContentCard';
import Api from '../../../../scripts/Api';
import PersonButton from '../../../../components/person-button/PersonButton';
import Router from '../../../../scripts/Router';
import TeamPlaceholder from '../../../../images/team-placeholder.jpg'

class TeamItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({imageLoading: true, coachesLoading: true, trainingsLoading: true});
        Api.get("/teams/"+this.props.team.id+"/image", response => {
            if (response.size > 0) {
                this.image = URL.createObjectURL(response);
            }
            else {
                this.image = TeamPlaceholder;
            }
            this.setState({imageLoading: false});
        }, () => {
            this.setState({imageLoading: false});
        }, 60*15);
        Api.get("/teams/"+this.props.team.id+"/coaches", d => {
            this.coaches = d.map((c,i) => <PersonButton key={i} person={c}></PersonButton>);
            this.setState({coachesLoading: false});
        }, e => {
            console.log(e);
            this.setState({coachesLoading: false});
        }, 60*15);
        var weekdays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
        Api.get("/teams/"+this.props.team.id+"/trainings", d => {
            this.trainings = d.map((t,i) => <div key={i}>{weekdays[t.weekday] + ", " + t.start_time + " - " + t.end_time + ", " + t.location.name}</div>);
            this.setState({trainingsLoading: false});
        }, e => {
            console.log(e);
            this.setState({trainingsLoading: false});
        }, 60*15);
    }

    onDetailClick(e) {
        Router.setPath(this.props.team.name, 1);
        e.preventDefault();
    }

    render() {
        return (
            <ContentCard>
                <h2 className="team-item-title">{this.props.team.name}</h2>
                <div className="team-item-content">
                    <img className="team-item-image" src={this.image} alt=""></img>
                    <div className="team-item-info">
                        <div className="team-item-info-coaches">
                            <h3>Trainer</h3>
                            {this.coaches}
                        </div>
                        <div className="team-item-info-trainings">
                            <h3>Trainings</h3>
                            {this.trainings}
                        </div>
                    </div>
                </div>
                <a className="team-item-button" href={"/teams/" + this.props.team.name} onClick={this.onDetailClick.bind(this)}>
                    Details
                </a>
            </ContentCard>
        );
    }

}

export default TeamItem;