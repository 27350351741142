import React, { Component } from 'react';
import './Header.css'

import Logo from '../../../images/logo_white.png'
import Tab from './Tab';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: true};
        this.lastScrollTop = window.document.body.scrollTop || window.document.documentElement.scrollTop;
        window.onscroll = this.onScroll.bind(this);
    }

    onTabClicked(key) {
        this.props.onTabChanged(key);
    }

    onScroll() {
        var current = window.document.body.scrollTop || window.document.documentElement.scrollTop;
        var expand = false;
        if (current < this.lastScrollTop) {
            expand = true;
        }
        this.lastScrollTop = current;
        if (this.state.expanded !== expand) {
            this.setState({expanded: expand});
        }
    }

    render() {
        var tabs = this.props.tabs.map((t) => <Tab key={t.key} title={t.title} path={t.key} active={this.props.current===t.key} onClick={this.onTabClicked.bind(this)}></Tab>);
        var c = "header";
        if (this.state.expanded) {
            c += " expand";
        }
        return (
            <div className={c}>
                <div className="header-content">
                    <img src={Logo} alt=""></img>
                    <div className="header-icons">
                        {this.props.iconButtons}
                    </div>
                </div>
                <div className="header-tab-bar">
                    {tabs}
                </div>
            </div>
        );
    }

}

export default Header;