import React, { Component } from 'react';
import './ActivityIndicator.css';

import loader from '../../images/loader.png'

class ActivityIndicator extends Component {

    render() {
        var cn = "activity-indicator";
        if (this.props.className !== "") {
            cn += " " + this.props.className;
        }
        return (
            <img className={cn} src={loader} alt=""></img>
        );
    }
}

export default ActivityIndicator;