import React, { Component } from 'react';
import './LocationItem.css'

class LocationItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="location-item">
            <h3>{this.props.location.name}</h3>
            <iframe className="location-item-map" title="Karte" src={"https://www.google.com/maps/embed/v1/place?q="+this.props.location.longitude+"%2C%20"+this.props.location.latitude+"&key=AIzaSyATi4chWRp0I3JiynmF2J6smpHpyzNee4I"}></iframe>
        </div>;
    }

}

export default LocationItem;