import React, { Component } from 'react';
import Header from './header/Header';
import Router from '../../scripts/Router';
import News from './news/News';
import './RootController.css'
import Teams from './teams/Teams';
import Beach from './beach/Beach';
import Board from './board/Board';
import Sponsors from './sponsors/Sponsors';
import DocumentIcon from '../../images/file-icon.png'
import ContactIcon from '../../images/contact-icon.png'
import Documents from './documents/Documents';
import Footer from './footer/Footer';
import Contact from './contact/Contact';

class RootController extends Component {

    constructor(props) {
        super(props);
        if (Router.getPath(0) === "") {
            Router.setPath("news", 0);
        }
        this.state = {currentTab: Router.getPath(0)};
        this.createTabs()
    }

    componentDidMount() {
        this.listenerID = Router.addChangeListener(this.updateRoute.bind(this));
    }

    componentWillUnmount() {
        Router.removeChangeListener(this.listenerID);
    }

    updateRoute() {
        this.setState({currentTab: Router.getPath(0)});
    }

    clickIconButton(key) {
        Router.setPath(key, 0);
    }

    createTabs() {
        this.tabs = [
            {title: "News", key: "news"},
            {title: "Teams", key: "teams"},
            {title: "Beach", key: "beach"},
            {title: "Vorstand", key: "vorstand"},
            {title: "Sponsoren", key: "sponsoren"}
        ];
        this.views = [
            <News key="name" name="news"></News>,
            <Teams key="teams" name="teams"></Teams>,
            <Beach key="beach" name="beach"></Beach>,
            <Board key="vorstand" name="vorstand"></Board>,
            <Sponsors key="sponsors" name="sponsoren"></Sponsors>,
            <Documents key="dokumente" name="dokumente"></Documents>,
            <Contact key="kontakt" name="kontakt"></Contact>
        ];
    }

    onTabChanged(key) {
        Router.setPath(key, 0);
    }

    render() {
        return (
            <div className="volley-hu">
                <Header tabs={this.tabs} current={this.state.currentTab} onTabChanged={this.onTabChanged.bind(this)} iconButtons={[
                    <div key="1" className="icon-button"><img alt="contact-button" src={ContactIcon} onClick={this.clickIconButton.bind(this, "kontakt")}/></div>,
                    <div key="2" className="icon-button"><img alt="documents-button" src={DocumentIcon} onClick={this.clickIconButton.bind(this, "dokumente")}/></div>
                ]}></Header>
                <div className="volley-hu-content">
                    {this.views.filter(v => v.props.name===this.state.currentTab)}
                </div>
                <Footer version={this.props.version}></Footer>
            </div>
        );
    }
}

export default RootController;