import React, { Component } from 'react';
import './ContentCard.css'

class ContentCard extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="content-card">
                {this.props.children}
            </div>
        );
    }

}

export default ContentCard;