import React, { Component } from 'react';
import './Documents.css'
import Parallax from '../../../components/parallax/Parallax';
import DocumentImage from '../../../images/documents.jpg'
import Api from '../../../scripts/Api';
import DocumentSection from './DocumentSection';

class Documents extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/files", d => {
            this.documents = d.map(f => <DocumentSection key={f.id} folder={f}></DocumentSection>);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <div>
                <Parallax className="documents-parallax">
                    <img className="documents-background" src={DocumentImage} alt=""></img>
                </Parallax>
                <div className="documents-content">
                    {this.documents}
                </div>
            </div>
        );
    }
}

export default Documents;