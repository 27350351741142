import React, { Component } from 'react';
import './Parallax.css'

class Parallax extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.contentNode = React.createRef();
        this.wrapper = React.createRef();
        this.speed = this.props.speed || 0.5;
        window.addEventListener("scroll", this.onScroll.bind(this));
    }

    componentDidMount() {
        var offset = this.wrapper.current.getBoundingClientRect();
        var scrollTop = window.document.body.scrollTop || window.document.documentElement.scrollTop;
        this.startY = scrollTop + offset.top;
        offset = this.wrapper.current.getBoundingClientRect();
        this.onScroll();
    }

    onScroll() {        
        if (!this.contentNode.current) return;
        var current = window.document.body.scrollTop || window.document.documentElement.scrollTop;
        var delta = this.startY + current;
        delta *= this.speed;
        this.contentNode.current.style.transform = "translateY("+delta+"px)";
    }

    render() {                
        var c = "";
        if (this.props.className) {
            c = " " + this.props.className;
        }
        return (
            <div className={"parallax" + c} ref={this.wrapper}>
                <div className="parallax-content" ref={this.contentNode}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

export default Parallax;