import React, { Component } from 'react';
import './DocumentSection.css'
import Api from '../../../scripts/Api';
import ContentCard from '../../../components/content-card/ContentCard';

import DocumentIcon from '../../../images/file-icon-dark.png'

class DocumentSection extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/files/"+this.props.folder.id, d => {
            this.documents = d.map(f => 
                <a className="document-section-item" key={f.id} href={Api.baseUrl+"/files/"+f.id} target="_blank" rel="noopener noreferrer">
                    <img className="document-section-item-image" alt="" src={DocumentIcon}/>
                    <div className="document-section-name">{f.name}</div>
                </a>
            );
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <ContentCard>
                <h3>{this.props.folder.name}</h3>
                {this.documents}
            </ContentCard>
        );
    }

}

export default DocumentSection;