import React, { Component } from 'react';
import './TeamDetail.css'
import ContentCard from '../../../../components/content-card/ContentCard';
import Api from '../../../../scripts/Api';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import PersonButton from '../../../../components/person-button/PersonButton';
import CollapseCard from '../../../../components/collapse-card/CollapseCard';
import GameDetail from '../../../../components/game-detail/GameDetail';
import TeamPlaceholder from '../../../../images/team-placeholder.jpg'

class TeamDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {tableCollapsed: true, gamesCollapsed: true, resultsCollapsed: true};
    }

    componentDidMount() {
        this.setState({imageLoading: true, coachesLoading: true, trainingsLoading: true, tableLoading: true, gamesLoading: true, resultsLoading: true});
        Api.get("/teams/"+this.props.team.id+"/image", response => {
            if (response.size > 0) {
                this.image = URL.createObjectURL(response);
            }
            else {
                this.image = TeamPlaceholder;
            }
            this.setState({imageLoading: false});
        }, () => {
            this.setState({imageLoading: false});
        }, 60*15, {full: true});
        Api.get("/teams/"+this.props.team.id+"/coaches", d => {
            this.coaches = d.map((c,i) => <PersonButton key={i} person={c}></PersonButton>);
            this.setState({coachesLoading: false});
        }, e => {
            console.log(e);
            this.setState({coachesLoading: false});
        }, 60*15);
        var weekdays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
        Api.get("/teams/"+this.props.team.id+"/trainings", d => {
            this.trainings = d.map((t,i) => <div key={i}>{weekdays[t.weekday] + ", " + t.start_time + " - " + t.end_time + ", " + t.location.name}</div>);
            this.setState({trainingsLoading: false});
        }, e => {
            console.log(e);
            this.setState({trainingsLoading: false});
        }, 60*15);        
        Api.get("/teams/"+this.props.team.id+"/games", d => {
            this.nextGames = d.map(g => <div key={g.game_id} className="team-detail-game"><GameDetail game={g}></GameDetail></div>);
            this.setState({gamesLoading: false});
        }, e => {
            console.log(e);
            this.setState({gamesLoading: false});
        }, 60*15);
        Api.get("/teams/"+this.props.team.id+"/results", d => {
            this.results = d.map(g => <div key={g.game_id} className="team-detail-game"><GameDetail game={g}></GameDetail></div>);
            this.setState({resultsLoading: false});
        }, e => {
            console.log(e);
            this.setState({resultsLoading: false});
        }, 60*15);
        Api.get("/teams/"+this.props.team.id+"/table", d => {
            if (d) {
                var table = [];
                var lastRank = 1;
                for (var i = 0; i < d.length; i++) {
                    if (d[i].rank === "&amp;nbsp;") {
                        d[i].rank = lastRank;
                    }
                    if (d[i].caption.indexOf("Hünenberg") !== -1) {
                        this.wins = d[i].wins;
                        this.sets = d[i].sets_won;
                        this.points = d[i].balls_won;
                        this.rank = d[i].rank;
                    }
                    
                    lastRank = d[i].rank;
                    table.push(
                        <div className="team-detail-table-row" key={d[i].team_id}>
                            <span className="team-detail-table-row-rank">{d[i].rank}</span>
                            <span className="team-detail-table-row-caption">{d[i].caption}</span>
                            <span className="team-detail-table-row-games">{d[i].no_games}</span>
                            <span className="team-detail-table-row-wins">{d[i].wins}</span>
                            <span className="team-detail-table-row-wins-clear">{d[i].wins_clear}</span>
                            <span className="team-detail-table-row-wins-narrow">{d[i].wins_narrow}</span>
                            <span className="team-detail-table-row-defeats">{d[i].defeats}</span>
                            <span className="team-detail-table-row-defeats-clear">{d[i].defeats_clear}</span>
                            <span className="team-detail-table-row-defeats-narrow">{d[i].defeats_narrow}</span>
                            <span className="team-detail-table-row-set-quotient">{d[i].set_quotient}</span>
                            <span className="team-detail-table-row-ball-quotient">{d[i].balls_quotient}</span>
                            <span className="team-detail-table-row-points">{d[i].points}</span>
                        </div>
                    );
                }
                this.table = table;
            }            
            this.setState({tableLoading: false});
        }, e => {
            console.log(e);
            this.setState({tableLoading: false});
        }, 60*15);
    }

    toggleTable() {
        this.setState({tableCollapsed: !this.state.tableCollapsed});
    }

    toggleGames() {
        this.setState({gamesCollapsed: !this.state.gamesCollapsed});
    }

    toggleResults() {
        this.setState({resultsCollapsed: !this.state.resultsCollapsed});
    }

    render() {
        return (
            <div className="team-detail">
                <ContentCard>
                    <div className="team-detail-image-container">
                        {this.state.imageLoading && <ActivityIndicator></ActivityIndicator>}
                        <img className="team-detail-image" src={this.image} alt={"Teamfoto"}></img>
                    </div>
                    <h2>{this.props.team.name}</h2>
                    <h3>Trainer</h3>
                    {this.coaches}
                    <h3>Trainings</h3>
                    {this.trainings}
                    {this.props.team.league &&  <div>
                        <h3>Liga</h3>
                        {this.props.team.league}
                    </div>}
                    {this.results && this.nextGames && this.table && <div>
                        <h3>Meisterschaft im Überblick</h3>
                        <div><div className="team-detail-league-key">Absolvierte Spiele:</div><div className="team-detail-league-value">{this.results.length}</div></div>
                        <div><div className="team-detail-league-key">Bevorstehende Spiele:</div><div className="team-detail-league-value">{this.nextGames.length}</div></div>
                        <div><div className="team-detail-league-key">Gewonnene Spiele:</div><div className="team-detail-league-value">{this.wins}</div></div>
                        <div><div className="team-detail-league-key">Gewonnene Sätze:</div><div className="team-detail-league-value">{this.sets}</div></div>
                        <div><div className="team-detail-league-key">Gewonnene Punkte:</div><div className="team-detail-league-value">{this.points}</div></div>
                        <div><div className="team-detail-league-key">Aktueller Rang:</div><div className="team-detail-league-value">{this.rank}</div></div>
                    </div>}
                </ContentCard>
                {this.table && <ContentCard>
                    <h2>Tabelle</h2>
                    <CollapseCard  collapsed={this.state.tableCollapsed}>
                        <div className="team-detail-table">
                            <div className="team-detail-table-row">
                                <span className="team-detail-table-row-rank">#</span>
                                <span className="team-detail-table-row-caption">Team</span>
                                <span className="team-detail-table-row-games">Spiele</span>
                                <span className="team-detail-table-row-wins">S</span>
                                <span className="team-detail-table-row-wins-clear">S+</span>
                                <span className="team-detail-table-row-wins-narrow">S-</span>
                                <span className="team-detail-table-row-defeats">N</span>
                                <span className="team-detail-table-row-defeats-clear">N+</span>
                                <span className="team-detail-table-row-defeats-narrow">N-</span>
                                <span className="team-detail-table-row-set-quotient">SQ</span>
                                <span className="team-detail-table-row-ball-quotient">BQ</span>
                                <span className="team-detail-table-row-points">Pkt.</span>
                            </div>
                            {this.table}
                        </div>
                    </CollapseCard>
                    <div onClick={this.toggleTable.bind(this)} className="team-detail-table-expand-button">
                        {(this.state.tableCollapsed && "Einblenden") || "Ausblenden"}
                    </div>
                </ContentCard>}
                {this.nextGames && this.nextGames.length > 0 && <ContentCard>
                    <h2>Spiele</h2>
                    <CollapseCard  collapsed={this.state.gamesCollapsed}>
                        {this.nextGames}
                    </CollapseCard>
                    <div onClick={this.toggleGames.bind(this)} className="team-detail-games-expand-button">
                        {(this.state.gamesCollapsed && "Einblenden") || "Ausblenden"}
                    </div>
                </ContentCard>}
                {this.results && this.results.length > 0 && <ContentCard>
                    <h2>Resultate</h2>
                    <CollapseCard  collapsed={this.state.resultsCollapsed}>
                        {this.results}
                    </CollapseCard>
                    <div onClick={this.toggleResults.bind(this)} className="team-detail-results-expand-button">
                        {(this.state.resultsCollapsed && "Einblenden") || "Ausblenden"}
                    </div>
                </ContentCard>}
            </div>            
        );
    }

}

export default TeamDetail;