import React, { Component } from 'react';
import './Footer.css'

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <footer className="footer">
                <div></div>
                <a href="https://lhsoftware.ch" className="footer-copyright">
                    <img alt="LHSoftware logo" src="https://image-provider.lhsoftware.ch/images/lhs?t=l&w=150"></img>
                    <div>&copy; 2017-{new Date().getFullYear()}</div>
                </a>
                <div className="footer-version">{this.props.version}</div>
            </footer>
        );
    }

}

export default Footer;