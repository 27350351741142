import React, { Component } from 'react';
import './Tab.css'

class Tab extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onClick() {
        this.props.onClick(this.props.path);
    }

    render() {
        var tabClass = "tab";
        if (this.props.active) {
            tabClass += " active";
        }
        return (
            <div className={tabClass} onClick={this.onClick.bind(this)}>
                <div className="tab-ripple"></div>
                <div className="tab-content">
                    {this.props.title}
                </div>
                <div className="tab-content-bar">

                </div>
            </div>
        );
    }

}

export default Tab;