import React, { Component } from 'react';
import './CoachSummary.css'
import EmailIcon from '../../../images/email_icon.png';

class CoachSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="coach-summary">
            <div className="coach-summary-name">
                <a className="coach-summary-person" href={"mailto:"+this.props.email}>
                    <img className="coach-summary-person-icon" src={EmailIcon} alt=""></img>
                    <span className="coach-summary-person-name">{this.props.name}</span>                    
                </a>
            </div>
            <div className="coach-summary-teams">
                {this.props.teamNames.map(t => 
                    <div key={t}>{t}</div>
                )}
            </div>            
        </div>;
    }

}

export default CoachSummary;