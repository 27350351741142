import React, { Component } from 'react';
import './CarouselItem.css'
import Api from '../../scripts/Api';

class CarouselItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.node = React.createRef();
    }

    componentDidUpdate(prevProps) {
        //console.log(this.props.left);
        if (this.node.current) {
            if (this.props.left > prevProps.left) {
                this.node.current.style["opacity"] = "0";
            }
            else {
                this.node.current.style["opacity"] = "1";
            }
            this.node.current.style["left"] = this.props.left+"px";
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/sponsor/"+this.props.sponsor.id+"/image", b => {
            this.image = URL.createObjectURL(b);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return <div className="carousel-item" ref={this.node}>
            <img className="carousel-item-image" src={this.image} alt=""/>
        </div>;
    }

}

export default CarouselItem;