import React, { Component } from 'react';
import './Sponsors.css'
import ContentCard from '../../../components/content-card/ContentCard';
import Parallax from '../../../components/parallax/Parallax';
import SponsorsImage from '../../../images/sponsoring.png';
import Api from '../../../scripts/Api';
import SponsorItem from './SponsorItem';

class Sponsors extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/sponsor", b => {
            this.sponsors = b.map(s => <SponsorItem key={s.id} sponsor={s}></SponsorItem>);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <div>
                <Parallax className="sponsors-parallax">
                    <img className="sponsors-background" src={SponsorsImage} alt=""></img>
                </Parallax>
                <ContentCard>
                    <div className="sponsors-content">
                        {this.sponsors}
                    </div>
                </ContentCard>
            </div>
        );
    }

}

export default Sponsors;