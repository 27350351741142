import React, { Component } from 'react';
import './News.css'
import Parallax from '../../../components/parallax/Parallax';
import NewsImage from '../../../images/news.jpg';
import Api from '../../../scripts/Api';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import ContentCard from '../../../components/content-card/ContentCard';
import GameDetail from '../../../components/game-detail/GameDetail';
import SponsorCarousel from '../../../components/sponsor-carousel/SponsorCarousel';

class News extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/games", b => {
            this.nextGames = b.games.map(g => g ? <ContentCard key={g.game_id}><GameDetail game={g}></GameDetail></ContentCard> : null);
            this.results = b.results.map(g => <ContentCard key={g.game_id}><GameDetail game={g}></GameDetail></ContentCard>);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <div className="news">
                <Parallax className="news-parallax">
                    <img className="news-background" src={NewsImage} alt=""></img>
                </Parallax>
                <div className="news-sponsors">
                    <ContentCard>
                        <SponsorCarousel></SponsorCarousel>
                    </ContentCard>
                </div>
                <div className="news-content">
                    {this.state.loading && <ContentCard><div className="news-content-loader"><ActivityIndicator></ActivityIndicator></div></ContentCard>}
                    {this.nextGames}
                    {this.results}
                </div>
            </div>
        );
    }

}

export default News;