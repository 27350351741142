import React, {Component} from 'react';
import './App.css';
import RootController from './root-controller/RootController';
import Api from '../scripts/Api';

class App extends Component {

    constructor(props) {
        super(props);
        //Api.setBaseUrl("http://localhost:8080/public");
        Api.setBaseUrl("https://api.volley.lhsoftware.ch/public");
        this.version = "v.3.1.2";
    }

    render() {
        return (
            <RootController version={this.version}></RootController>
        );
    }
}

export default App;
