import React, { Component } from 'react';
import './Beach.css'
import ContentCard from '../../../components/content-card/ContentCard';
import Parallax from '../../../components/parallax/Parallax';
import BeachImage from '../../../images/beach.jpg';

class Beach extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Parallax className="beach-parallax">
                    <img className="beach-background" src={BeachImage} alt=""></img>
                </Parallax>
                <ContentCard>
                    <div className="beach-content">
                        <p>
                            In der Badi Hünenberg wurden bereits anfangs der 90er Jahre auf Initiative des damaligen Vorstandes von Volley Hünenberg zwei Beachvolleyballfelder gebaut. 
                            Diese werden seither vom Beachressort mit Helfern instand gehalten, gepflegt und verwaltet. Ab anfangs April bis in den Herbst hinein sind die Felder bereit.
                            Auch wenn unsere Mannschaften fast ausschliesslich in der Halle trainieren und spielen, führen wir doch während der Sommerferien unser Sommertraining im 
                            Sand durch.
                        </p>
                        <p>
                            Als Mitglied des Regionalverbandes Innerschweiz führen wir im Rahmen der RVI Coop Beachtour ausserdem diverse Turniere durch.
                        </p>
                        <p>
                            Vor allem werden die Felder jedoch rege von den Gästen der Badi genutzt.
                        </p>
                        <p>
                            Es besteht zudem die Möglichkeit für Schulen, Vereine und Firmen die Felder zu reservieren.
                        </p>
                        <p>
                            Interessant für regionales Gewerbe ist die Möglichkeit der Bandenwerbung.
                        </p>
                    </div>
                </ContentCard>
            </div>
        );
    }

}

export default Beach;