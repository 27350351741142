import React, { Component } from 'react';
import './SponsorItem.css'
import Api from '../../../scripts/Api';

class SponsorItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/sponsor/"+this.props.sponsor.id+"/image", b => {
            this.image = URL.createObjectURL(b);
            this.setState({loading: false});
        }, e => {
            console.log(e);
        }, 60*15);
    }

    render() {
        return (
            <a href={this.props.sponsor.link} className="sponsor-item" target="_blank" rel="noopener noreferrer">
                <img className="sponsor-item-image" src={this.image} alt=""/>
                <div className="sponsor-item-text-wrapper">
                    <div className="sponsor-item-text">{this.props.sponsor.description}</div>
                </div>
            </a>
        );
    }

}

export default SponsorItem;