import React, { Component } from 'react';
import './PersonButton.css'


import EmailIcon from '../../images/email_icon.png';

class PersonButton extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <a className="person-button" href={"mailto:"+this.props.person.email}>
                <span>{this.props.person.first_name + " " + this.props.person.last_name}</span>
                <img className="person-button-icon" src={EmailIcon} alt=""></img>
            </a>
        );
    }

}

export default PersonButton;