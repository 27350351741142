import React, { Component } from 'react';
import './CollapseCard.css'

class CollapseCard extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.node = React.createRef();
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.node.current && !this.state.maxHeight) {
            var contentHeight = this.node.current.getBoundingClientRect()["height"];
            if (contentHeight > 50) {
                this.setState({maxHeight: contentHeight});
                this.node.current.style["transition"] = (contentHeight/1500)+"s linear max-height";
            }
        }
    }

    render() {        
        var state;
        if (this.props.collapsed) {
            state = "collapsed";
            if (this.node.current && this.state.maxHeight) {
                this.node.current.style["max-height"] = "0px";
            }
        }
        else {
            state = "open";
            if (this.node.current) {
                this.node.current.style["max-height"] = this.state.maxHeight+"px";
            }
        }
        return (
            <div className={"collapse-card " + state} ref={this.node}>
                {this.props.children}
            </div>
        );
    }

}

export default CollapseCard;