import React, { Component } from 'react';
import './Contact.css'
import ContactImage from '../../../images/contact.jpg'
import Parallax from '../../../components/parallax/Parallax';
import Api from '../../../scripts/Api';
import LocationItem from './LocationItem';
import ContentCard from '../../../components/content-card/ContentCard';
import CoachSummary from './CoachSummary';

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({loadingLocations: true, loadingCoaches: true, loadingBoard: true});
        Api.get("/locations", res => {
            this.locationItems = res.map(l => <LocationItem location={l} key={l.id}></LocationItem>);
            this.setState({loadingLocations: false});
        }, e => {
            console.log(e);
        }, 600);
        // load coaches
        var coachesToLoad = 0;
        var coachMap = {};
        var emailMap = {};
        var coachSuccess = function(team, res){
            for (var i = 0; i < res.length; i++) {
                var coachName = res[i].first_name + " " + res[i].last_name;
                if (!coachMap[coachName]) {
                    coachMap[coachName] = [];
                    emailMap[coachName] = res[i].email;
                }
                coachMap[coachName].push(team.name);
            }
            coachesToLoad--;
            if (coachesToLoad === 0) {
                this.coachSummaries = [];
                for (var key in coachMap) {
                    this.coachSummaries.push(<CoachSummary key={key} teamNames={coachMap[key]} name={key} email={emailMap[key]}></CoachSummary>);
                }
                this.setState({loadingCoaches: false});
            }
        };
        var coachErr = function(e) {
            console.log(e);
        };
        Api.get("/teams", res => {
            coachesToLoad = res.length;
            for (var i = 0; i < res.length; i++) {
                Api.get("/teams/"+res[i].id+"/coaches", coachSuccess.bind(this, res[i]), coachErr, 600);
            }
        }, e => {
            console.log(e);
        }, 600);
        // load board members
        Api.get("/board", res => {
            console.log(res);
            this.boardItems = res.map(b => <CoachSummary key={b.id} teamNames={[b.job]} name={b.name} email={b.email}></CoachSummary>)
            this.setState({loadingBoard: false});
        }, e => {
            console.log(e);
        });
    }

    render() {
        return <div>
            <Parallax className="contact-parallax">
                <img className="contact-background" src={ContactImage} alt=""></img>
            </Parallax>
            <div className="contact-content">
                <ContentCard>
                    <h2>Hallen</h2>
                    {this.locationItems}
                </ContentCard>
                <ContentCard>
                    <h2>Alle Personen</h2>
                    <h4>Vorstand</h4>
                    {this.boardItems}
                    <h4>Trainer</h4>
                    {this.coachSummaries}                    
                </ContentCard>
            </div>
        </div>;
    }

}

export default Contact;