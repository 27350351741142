import React, { Component } from 'react';
import './GameDetail.css'

import ContactIcon from '../../images/contact-icon-black.png'

class GameDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var setsHome = " ";
        var setsAway = " ";
        if (this.props.game.result !== "") {
            var splitted = this.props.game.result.split(":");
            setsHome = splitted[0];
            setsAway = splitted[1];
        }
        var result;
        if (this.props.game.set_points !== "") {
            result = this.props.game.set_points;
        }
        var league = this.props.game.league;
        league = this.props.game.league.split("-")[0];
        if (this.props.game.gender === "m") {
            league = "Herren, " + league;
        }
        else {
            league = "Damen, " + league;
        }
        var time = this.props.game.time.substring(0, 5);
        return (
            <div className="game-detail">
                <div className="game-detail-title">
                    <div className="game-detail-team-home">{this.props.game.team_home}</div>
                    <div className="game-detail-result">{setsHome}</div>
                    <div className="game-detail-seperator">:</div>
                    <div className="game-detail-result">{setsAway}</div>
                    <div className="game-detail-team-away">{this.props.game.team_away}</div>
                </div>
                <div className="game-detail-table">
                    {result && 
                    <div className="game-detail-table-row">
                        <div className="game-detail-table-row-key">Resultat:</div>
                        <div className="game-detail-table-row-value">{result}</div>
                    </div>}
                    <div className="game-detail-table-row">
                        <div className="game-detail-table-row-key">Liga:</div>
                        <div className="game-detail-table-row-value">{league}</div>
                    </div>
                    <div className="game-detail-table-row">
                        <div className="game-detail-table-row-key">Datum:</div>
                        <div className="game-detail-table-row-value">{this.props.game.date}</div>
                    </div>
                    <div className="game-detail-table-row">
                        <div className="game-detail-table-row-key">Zeit:</div>
                        <div className="game-detail-table-row-value">{time}</div>
                    </div>
                    <div className="game-detail-table-row">
                        <div className="game-detail-table-row-key">Halle:</div>
                        <div className="game-detail-table-row-value">
                            <a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/search/?api=1&query=" + this.props.game.hall_name + ", " + this.props.game.hall_address + ", " + this.props.game.hall_place}>
                                <span>{this.props.game.hall_name}</span>
                                <img src={ContactIcon} alt="" className="game-detail-location-marker"></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default GameDetail;